// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background: linear-gradient(90deg, rgba(169,49,246,1) 0%, rgba(0,15,142,1) 100%); */

}

.app-wrapper {
  background: linear-gradient(270deg, #000f8e 25%, #a931f6 129.76%);  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.content-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  min-height: 15vh;
  position: relative;
  top: 0;
  opacity: 1;
  float: left;
  padding: 60px 50px 40px 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 5;
  max-width: 330px;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 115%;
  
  /* Add these lines */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.submit-button {
  width: inherit;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 3px;
  height: 7vh;
  background-color: #0000a0;
  justify-content: center;
  align-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,sFAAsF;;AAExF;;AAEA;EACE,iEAAiE,GAAG,aAAa;EACjF,sBAAsB;EACtB,iBAAiB;AACnB;;;AAGA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,WAAW;EACX,4BAA4B;EAC5B,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,UAAU;EACV,gBAAgB;EAChB,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;EAEjB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;;AAGA;EACE,cAAc;EACd,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":["body, html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  /* background: linear-gradient(90deg, rgba(169,49,246,1) 0%, rgba(0,15,142,1) 100%); */\n\n}\n\n.app-wrapper {\n  background: linear-gradient(270deg, #000f8e 25%, #a931f6 129.76%);  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n\n.content-container {\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.box {\n  min-height: 15vh;\n  position: relative;\n  top: 0;\n  opacity: 1;\n  float: left;\n  padding: 60px 50px 40px 50px;\n  width: 100%;\n  background: #fff;\n  border-radius: 10px;\n  transform: scale(1);\n  -webkit-transform: scale(1);\n  -ms-transform: scale(1);\n  z-index: 5;\n  max-width: 330px;\n  font-family: \"Titillium Web\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 115%;\n  \n  /* Add these lines */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n\n.submit-button {\n  width: inherit;\n  font-family: \"Titillium Web\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  border-radius: 3px;\n  height: 7vh;\n  background-color: #0000a0;\n  justify-content: center;\n  align-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
