
import * as React from 'react';
import axios from "axios";
import './App.css';
import'./index.css';
import { useKeycloak } from "@react-keycloak/web";
import {useEffect, useState,useRef } from "react";
import {
  Backdrop,
  Box, Checkbox,
  Button, Pagination, Stack,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, FormControlLabel, FormGroup
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl} from 'react-syntax-highlighter/dist/esm/styles/prism';

function Home() {
  const { keycloak } = useKeycloak();
  const [openRevoke, setOpenRevoke] = React.useState(false);

  const [openSuspense, setOpenSuspense] = React.useState(false);

  const [openReactivate, setOpenReactivate] = React.useState(false);

  const [openload, setOpenLoad] = useState(true);
  const [opendialog, setOpenDialog] = useState(false);
  const [valuedialog, setValueDialog] = useState('');
  const [showvc, setShowVc] = useState(false);
  const [viewvc, setViewVc] = useState({});
  const [valuevc, setValueVC] = useState({});
  const [parameters,setParameters]=useState('');
  const [limit, setLimit] = useState(10);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterMode, setIsFilterMode] = useState(false);

  const [sizetab, setSizeTab] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [VC, setVC] = useState("");

  const inputRef = useRef(null);

  //fin gestion slider
  const { index } = useParams();
  const folderUrl='/portail/';


  const icpUrl = process.env.REACT_APP_ICP_URL;

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#003DA5',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    }));


  const getIndexFromPathname = () => (typeof index == 'undefined' ? 'null' : !Number.isNaN((Number(index))) ? parseInt(index) : 'undefined');

  const getConfirmation = (vc) => {
    setVC(vc);
    if (vc.statusPurpose === "revocation" && vc.status === "revocation") {
      setOpenRevoke(true);
    } 
    else if (vc.statusPurpose === "suspension") {
      (vc.status === "suspended") ? setOpenReactivate(true) : setOpenSuspense(true);
    }
  };

  const handleChange = (event) => {
    let valstatus = '';
    let newIsChecked2 = isChecked2;
    event.target.name === 'checkbox2' ? newIsChecked2 = event.target.checked : newIsChecked2 = '';


    if (newIsChecked2) {
      valstatus = '&filter={"object.status":"suspended"}';
    } 
    else {
      valstatus = '';
    }

    valstatus += '&' + parameters;
    setIsChecked2(newIsChecked2);
    setCurrentPage(1);
    const baseQuery = `?offset=${0}&limit=${limit}${valstatus}`;
    window.history.replaceState({}, '', folderUrl + baseQuery);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const desableFilter = (async () => {
    try {
      if (inputRef.current.value.length === 0) {
        setOpenDialog(true);
        setValueDialog("Enter a search value.");
      } else {
        let valstatus='';
        if (isChecked1 && isChecked2) {
          valstatus = '&'+ ajouterContenuAuFiltre('filter={"object.status":"suspended"}', JSON.parse(inputRef.current.value));
        } else if (isChecked1 && !isChecked2) {
          valstatus = '&'+ ajouterContenuAuFiltre('filter={"object.status":"revoked"}', JSON.parse(inputRef.current.value));
        } else if (!isChecked1 && isChecked2) {
          valstatus = '&'+ ajouterContenuAuFiltre('filter={"object.status":"suspended"}', JSON.parse(inputRef.current.value));
        } else {
          valstatus = '&'+ ajouterContenuAuFiltre('filter={}', JSON.parse(inputRef.current.value));
        }
        setParameters(JSON.parse(inputRef.current.value))
        setIsFilterMode(true);
        setCurrentPage(1)
        window.history.replaceState({}, '', folderUrl+`?offset=${currentPage-1}&limit=${limit}${valstatus}`);
        inputRef.current.disabled = true;
        }
      }
    catch (e) {
      setOpenDialog(true);
      setValueDialog("Vérifier que les valeurs de la recherche sont au bon format Json.");
      console.log(e);
    }
  });

  const enableFilter = (async () => {
    setSearchTerm('')
    setParameters({})
    let valstatus='';
    if (isChecked1 && isChecked2) {
      valstatus = '&filter={"object.status":"suspended"}';
    } else if (isChecked1 && !isChecked2) {
      valstatus = '&filter={"object.status":"revoked"}';
    } else if (!isChecked1 && isChecked2) {
      valstatus = '&filter={"object.status":"suspended"}';
    }
    console.log("la : " + valstatus)
    window.history.replaceState({}, '', folderUrl+`?offset=${currentPage}&limit=${limit}${valstatus}`);
      setIsFilterMode(false)
      inputRef.current.disabled = false;
  });


  function getEntier(nombre) {
    if (nombre > 0) {
      return Math.ceil(nombre);
    } else {
      return 0;
    }
  }

  function getParams(jsonObject) {
    for (const key in jsonObject) {
      if (key !== 'status') {
        return true;
      } else {
        const statusValue = jsonObject[key];
        if (statusValue !== 'revoked' && statusValue !== 'suspended') {
          return true;
        }
      }
    }
    return false;
  }


  const fetchVerificationCredentials = (async (position,currentPages,limit,statusParam,params) => {
    try {
      let valurl='';

      if (position === 'null') {
        valurl = `?offset=${currentPages}&limit=${limit}`;
      } else if (position === 'undefined') {
        valurl = `${position}`;
      } else {
        valurl = `${position}`;
      }

      const searchParams = new URLSearchParams(window.location.search);
      const otherParams = {};
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'filter' && key !== 'offset' && key !== 'limit') {
          otherParams[key] = value;
        }
      }

      const filterParam = searchParams.get('filter');
      const parametre = JSON.parse(decodeURIComponent(filterParam));
      if (getParams(parametre)) {
        if (!isFilterMode) {
          setParameters(parametre)
          setIsFilterMode(true);JSON.stringify()
        }
        inputRef.current.disabled = true;
        setSearchTerm(JSON.stringify(parametre));
      } else {
        if (!isFilterMode) {
          setIsFilterMode(false);
        }
        inputRef.current.disabled = false;
        setIsFilterMode(false)
      }
      
      if (isChecked1 && isChecked2) {
        valurl += '&'+ ajouterContenuAuFiltre('filter={"object.status":"suspended"}', parametre);
      } 
      else if (!isChecked1 && !isChecked2) {
        if (position === 'null') {
          valurl += '&'+ ajouterContenuAuFiltre('filter={}', parametre);
        }
      } 
      else if (isChecked1 && !isChecked2) {
        valurl += '&'+ ajouterContenuAuFiltre('filter={"object.status":"revoked"}', parametre);
      }
      else if (!isChecked1 && isChecked2) {
        valurl += '&'+ ajouterContenuAuFiltre('filter={"object.status":"suspended"}', parametre);
      }

      const response = await axios.get(`${icpUrl}/revocations${valurl}`);

      setViewVc(response.data.credentials);
      setSizeTab(getEntier(response.data.count/limit));

      if (statusParam === '{"status":"revoked"}') {
        setIsChecked1(true)
      } else if (statusParam === '{"object.status":"suspended"}') {
        setIsChecked2(true)
      } else if (statusParam === '{"object.status":"suspended"}') {
        setIsChecked1(true)
        setIsChecked2(true)
      }

      if(Number.isNaN(Number(currentPages))){
        setCurrentPage( 1);
      }

      handleCloseLoad();
      
    } catch (error) {
      console.error('Error fetching data:', error);
      handleCloseLoad();
    }

  });

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    document.title = "Portal admin";
    const params = new URLSearchParams(window.location.search);
    params.delete('session_state');
    params.delete('code');
    const updatedUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.history.replaceState({}, '', updatedUrl);
    const valpage = params.get('offset');
    const vallimit = params.get('limit');
    const statusParam = params.get('filter');
    const position = getIndexFromPathname();

    if(valpage != null && position === 'null'){
      const urlPage = getEntier(valpage/vallimit);
      setLimit(vallimit ? parseInt(vallimit, 10) : 10);

      if(parseInt(valpage)<parseInt(vallimit)) {
        const currentPageValue = parseInt(valpage);
        setCurrentPage(valpage ? parseInt( currentPageValue === 0 ? urlPage + 1 : urlPage, 10) : 0);
        fetchVerificationCredentials(position, currentPageValue, vallimit, statusParam, params);

      } else {
          setCurrentPage(valpage ? parseInt(valpage/vallimit,10)+1 : 0);
          fetchVerificationCredentials(position, valpage, vallimit, statusParam, params);
      }
    } else{
      fetchVerificationCredentials(position,0,10,statusParam,params);
      handleCloseLoad()
    }

  }, [initialLoad, currentPage, isChecked1, isChecked2, isFilterMode, parameters]);

  const updateURL = (page, limit) => {
    const params = new URLSearchParams(window.location.search);
    params.set('offset', page ? parseInt(page, 10) : 0);
    params.set('limit', limit ? parseInt(limit, 10) : 1);
    const updatedUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', updatedUrl);
  };  
  
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    updateURL((page-1)*limit, limit);
  };

  function ajouterContenuAuFiltre(filterString, jsonObject) {
    const filterObj = JSON.parse(filterString.replace('filter=', ''));
    const mergedObj = { ...filterObj, ...jsonObject };
    const mergedString = JSON.stringify(mergedObj);
    const result = `filter=${mergedString}`;
    return result;
  }

  const columns = [
    { field: 'id', headerName: 'Index', headerClassName: 'column-text', width: 120, headerAlign: 'center', align: 'center'},
    { field: 'id_vc', headerName: 'Id of Credential', headerClassName: 'column-text', width: 700, headerAlign: 'center', align: 'center'},
    { field: 'issuanceDate', headerClassName: 'column-text', headerName: 'Issuance Date',   width: 210, headerAlign: 'center', align: 'center'},
    {
      field: 'expirationDate',
      headerName: 'Expiration Date', headerClassName: 'column-text',
      width: 210, headerAlign: 'center', align: 'center'
    },
    {
      field: 'statut',
      headerName: 'Statut',
      description: '', headerClassName: 'column-text',
      sortable: false,
      width: 120, headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        let status = '';
        if (params.row.statusPurpose === "revocation") {
          if(params.row.status === "revoked") {
            status = <span className="span-revok"> <span className = "circle-span-revok"></span><span> Revoked</span></span>;
          } else {
            status = <span className="span-not-revok"> <span className = "circle-span-not-revok"></span><span> Valid</span></span>;
          }
        } else if (params.row.statusPurpose === "suspension") {
          if (params.row.status === "suspended") {
            status = <span className ="span-suspend"> <span className = "circle-span-suspend"></span><span> Suspended</span></span>;
        } else {
          status = <span className = "span-not-suspend"> <span className = "circle-span-not-suspend"></span><span> Valid</span></span>;
        }
      }
      return status;
    },
    }, {
      field: 'action',
      headerName: 'Action', headerClassName: 'column-text',
      description: 'Gestion des action sur les VC',
      sortable: false,
      width: 150, headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        if (params.row.statusPurpose === "revocation") {
          if (params.row.status === "revoked") {
            return (<FormGroup> <FormControlLabel disabled control={ <IOSSwitch sx={{ m: 1 }} /> } /> </FormGroup>)
          }
          else{
            return (<FormGroup> <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} onChange={() => getConfirmation(params.row)} checked /> } /> </FormGroup>)
          }
        }
        else {
          if (params.row.status === "suspended") {
            return (<FormGroup> <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} onChange={() => getConfirmation(params.row)} /> } /> </FormGroup>)
          }
          else {
            return (<FormGroup> <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} onChange={() => getConfirmation(params.row)} checked /> } /> </FormGroup>)
          }
        }
      }
    },
    {
      field: 'see_vc',
      headerName: 'See', headerClassName: 'column-text',
      description: '',
      sortable: false,
      width: 180, headerAlign: 'center', align: 'center',
      renderCell: (params) => (
          <Button variant="contained"  style={{fontSize:'12px' ,width:'200px', backgroundColor:'#000F8E' , color: "white", fontFamily: "Arial"}}  onClick={() => seeVc(params.row.id)}>
            SEE
          </Button>
      ),
    }
  ];

  const isRevoked = async (params) => {

  return await axios.post(`${icpUrl}/revocations/api/check/VC`, {
      "credentialStatus": {
        "type": "StatusList2021Entry",
        "id": params.id_status_credential,
        "statusPurpose": params.statusPurpose,
        "statusListIndex": params.statusListIndex,
        "statusListCredential": params.status_credential
      }
    });
  };
  function rowValue(data) {
    if (!Array.isArray(data)) {
      return [];
    }
    const row = data.map((item, index) => {
      const credential = item.credential;
      return {
        id: index,
        id_vc: credential.id,
        name: item.name,
        issuer: credential.issuer,
        issuanceDate: new Date(credential.validFrom).toLocaleString(),
        issued: new Date(credential.validFrom).toLocaleString(),
        expirationDate: new Date(credential.expirationDate).toLocaleString(),
        id_status_credential: credential.credentialStatus.id,
        status_credential: credential.credentialStatus.statusListCredential,
        statusPurpose: credential.credentialStatus.statusPurpose,
        statusListIndex: credential.credentialStatus.statusListIndex,
        status: item.status
      }
    });

    return row;
  }
  const rows = rowValue(viewvc);

  const handleClosec = () => {
    setShowVc(false);
  };

  const seeVc = async (id) => {
    const vcValue = viewvc[id].credential;
    if (vcValue) {
      setShowVc(true);
      setValueVC(vcValue)
    } else {
      console.log('absence of vc.');
    }
  };
  
  const revokeVc = async (params) => {
    handleCloseRevoke();
    handleOpenLoad();
    const result = await isRevoked(params);
    await axios.post(`${icpUrl}/revocations/` + params.id_vc, {
      "newStatus": "revoked"
    }).then(response => {
      if (result.data.status === "revoked") {
        handleCloseLoad();
        window.location.reload();
      } else {
        handleCloseLoad();
        handleOpenDialog()
        setValueDialog("The VC has already been revoked.");
      }
    }).catch(error => {
      console.error(error);
      handleCloseLoad();
    });
  };

  const suspenseVc = async (params) => {
    handleCloseSuspense()
    handleOpenLoad()

    await axios.post(`${icpUrl}/revocations/` + encodeURI(params.id_vc), { "newStatus": "suspended" }).then(response => {
      if (response.data.status === "suspended") {
        handleCloseLoad();
        window.location.reload();
      } else {
        handleCloseLoad();
        handleOpenDialog()
        setValueDialog("The VC has already been suspended.");
      }
    }).catch(error => {
      console.error(error);
      handleCloseLoad();
    });
  };

  const handleCloseLoad = () => {
    setOpenLoad(false);
  };

  const handleOpenLoad = () => {
    setOpenLoad(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseReactivate = () => {
    setOpenReactivate(false)
  };

  const handleCloseRevoke = () => {
    setOpenRevoke(false)
  };

  const handleCloseSuspense = () => {
    setOpenSuspense(false)
  };

  const reactivateVc = async (params) => {
    handleCloseReactivate()
    handleOpenLoad();
    await axios.post(`${icpUrl}/revocations/${params.id_vc}`, { "newStatus": "alive" }).then(response => {
      if (response.data.status === "alive") {
        handleCloseLoad();
        window.location.reload();
      } else {
        handleCloseLoad();
        handleOpenDialog()
        setValueDialog("You cannot reactivate because the VC has not been suspended.");
      }
    }).catch(error => {
      console.error(error);
      handleCloseLoad();
    });
  };

 
    
  return (
  <Box sx = {{ fontFamily:'Arial' }} className='corp'>
    <Dialog
        open = {opendialog}
        onClose = {handleCloseDialog}
    >
      <DialogTitle><u>{"Message"}</u></DialogTitle>
      <DialogContent>
        <DialogContentText id = "alert-dialog-slide-description" sx = {{color:'black'}}>
          {valuedialog}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick = {handleCloseDialog}>OK</Button>
      </DialogActions>
    </Dialog>
        <Backdrop
          sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
         open = {openload}>

    <CircularProgress color = "inherit" />
  </Backdrop>
        {showvc && (
              <div>
              <Dialog open = {showvc} onClose = {handleClosec} sx = {{ color: '#fff', fontFamily: "Arial", fontSize:"17px", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <DialogTitle style = {{color:'white', background: 'linear-gradient(270deg, #000F8E 25%, #A931F6 129.76%)'}}>Content</DialogTitle>
                 <SyntaxHighlighter language="json" style={nightOwl}  customStyle={{ width: '90%', padding: '30px' }}>
                             {JSON.stringify(valuevc, null, 2)}
    </SyntaxHighlighter>
              </Dialog>
            </div>
        )}

  { openSuspense && (<Dialog
        open = { openSuspense }
        onClose = { handleCloseSuspense }
        aria-labelledby = "alert-dialog-title"
        aria-describedby = "alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Message
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       are you sure you want to suspend the VC?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick = {handleCloseSuspense}>Disagree</Button>
          <Button onClick = {()=>suspenseVc(VC)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>)}

      
{ openRevoke && (<Dialog
        open = { openRevoke }
        onClose = { handleCloseRevoke }
        aria-labelledby = "alert-dialog-title"
        aria-describedby = "alert-dialog-description"
      >
        <DialogTitle id = "alert-dialog-title">
         Message
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          are you sure you want to revoke the VC?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick = { handleCloseRevoke }>Disagree</Button>
          <Button onClick = { () => revokeVc(VC) } autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>)}


      { openReactivate && (<Dialog
        open = { openReactivate }
        onClose = { handleCloseReactivate }
        aria-labelledby = "alert-dialog-title"
        aria-describedby = "alert-dialog-description"
      >
        <DialogTitle id = "alert-dialog-title">
      Message
        </DialogTitle>
        <DialogContent>
          <DialogContentText id = "alert-dialog-description">
          are you sure you want to reactivate the VC?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick = { handleCloseReactivate }>Disagree</Button>
          <Button onClick = { () => reactivateVc(VC) } autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>)}
      <Box
          sx = {{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'white',
            mb: '20px',
            minHeight: '55px',
            paddingLeft: '2vw',
            marginBottom: '0vh'
          }}
        >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.76562 9.6875C6.02083 9.6875 5.27604 9.41667 4.73438
                    8.80729L0.9375 5.00521C-0.213542 3.85417 -0.213542 2.02083
                    0.9375 0.937499C2.08854 -0.21875 3.92187 -0.21875 5.00521
                    0.937499L8.79688 4.73437C9.95313 5.89062 9.95313 7.71875 8.79688
                    8.80729C8.25521 9.41667 7.51042 9.6875 6.76562 9.6875Z" fill="#000F8E" />
                <path d="M16.0521 9.6875C15.3073 9.6875 14.5625 9.41667 14.0156
                        8.80729C12.8646 7.65104 12.8646 5.82292 14.0156
                        4.73437L17.8125 0.937499C18.9635 -0.21875 20.7969 -0.21875
                        21.8802 0.937499C23.0313 2.08854 23.0313 3.92187 21.8802
                        5.00521L18.0833 8.80729C17.5417 9.41667 16.7969 9.6875
                        16.0521 9.6875Z" fill="#000F8E" />
                <path d="M2.96875 22.8542C2.22396 22.8542 1.47917 22.5834
                            0.9375 21.9688C-0.213542 20.8177 -0.213542 18.9844
                            0.9375 17.9011L4.73438 14.099C5.88542 12.948 7.71354
                            12.948 8.79688 14.099C9.95313 15.2552 9.95313 17.0834
                            8.79688 18.1719L5.00521 21.9688C4.39583 22.5834 3.71354
                            22.8542 2.96875 22.8542Z" fill="#000F8E" />
                <path d="M19.8438 22.8542C19.099 22.8542 18.3542 22.5834
                                17.8125 21.9688L14.0156 18.1719C12.8646 17.0157
                                12.8646 15.1875 14.0156 14.099C15.1719 12.948 17
                                12.948 18.0833 14.099L21.8802 17.9011C23.0313
                                19.0521 23.0313 20.8855 21.8802 21.9688C21.3385
                                22.5834 20.5938 22.8542 19.8438 22.8542Z" fill="#000F8E" />
            </svg>
          <h1
            style={{
              fontFamily: 'Arial',
              color: '#000F8E',
              textAlign: 'end',
              marginRight: '30px'
            }}
          >
            <Button
              variant="outlined"
              style={{ fontSize: '12px', backgroundColor: 'white', fontFamily: "Arial", marginRight:"2vw" }}
              color="secondary"
              onClick={keycloak.logout}
            >
              Logout
            </Button>
          </h1>
        </Box>
    <Box sx = {{ width: '100%', height: '154px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem', marginRight:'1rem' }} className='headerPortail'>
      <h1 style={{width: '30%', fontFamily: 'Arial', textAlign: 'center', color: 'white', marginLeft: '60px', marginTop: '5px' }}>
        Administrator page
      </h1>

      <div className="search-container" style={{  marginRight: '35px',width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '5px' }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px' ,color:'white'}}>
          <FormControlLabel
            control={<Checkbox checked={isChecked2} name="checkbox2" sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
            label="Show suspended Vcs"
            onChange={handleChange}
          />
        </FormGroup>

        <input
          type="text"
          value={searchTerm}
          ref={inputRef}
          onChange={handleInputChange}
          className="custom-input"
          placeholder="Search..."
        />

        {!isFilterMode ? (
          <Button onClick={desableFilter} className="custom-button" variant="outlined" sx={{ ml: '1rem', fontSize: "12px", fontWeight:'bold', backgroundColor: "white", color: "#000F8E", fontFamily: "Arial",'&:hover': { backgroundColor: 'white' } }}>Start a reseach</Button>
        ) : (
          <Button onClick={enableFilter} className="custom-button" variant="outlined" sx={{ ml: '1rem', fontSize: "12px", backgroundColor: "white", fontWeight:'bold',color: "#000F8E", fontFamily: "Arial" ,'&:hover': { backgroundColor: 'white' } }}>cancel search</Button>
        )}
      </div>
    </Box>
          <div>
          <DataGrid
  style={{ fontFamily: "Arial", marginRight:'2rem', marginTop:'-3rem', marginLeft:'2rem', backgroundColor: 'white', fontSize:'14px' }}
  rows={rows}
  columns={columns}
  autoHeight
  initialState={{
    pagination: {
      page: 0,
      pageSize: limit,
    },
  }}
  pageSizeOptions={[0, limit]}
/>
              <Stack spacing={2} sx={{mt:'20px'}}>
        <Pagination count={sizetab} sx={{marginLeft:'60px',
    '& .MuiPaginationItem-page.Mui-selected': {
      color: 'white',
      backgroundColor:'#000F8E'
    },
    '& .MuiPaginationItem-page': {
      color: '#000F8E',
    }}} variant="outlined" shape="rounded"  page={currentPage}
    onChange={handlePageChange} />

         </Stack>
          </div>
        </Box>
     
 
  )
}
export default Home;

