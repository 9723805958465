import { useEffect } from "react";
import { Box, Button, Container, Card } from "@mui/material";
import './Login.css';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';

function Login() {
    useEffect(() => {
        document.title = "Login"
    }, []);
    const { keycloak } = useKeycloak();
 
    const logKeycloak = () => {
        const url = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/IcpAdministration/protocol/openid-connect/auth?client_id=IcpAdministration&response_type=code&scope=openid%20profile&redirect_uri=${window.location.origin}/portail`;
        window.location.href = url;
    };

    const navigate = useNavigate();

    if (keycloak.authenticated) {
        navigate('/portail'); // Redirect to the root path
        return null;
      }
    return (
        <div className="app-wrapper">
           <Box
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: 'white',
                mb: '20px',
                minHeight:'55px',
                paddingLeft:'2vw',
            }}
            >
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.76562 9.6875C6.02083 9.6875 5.27604 9.41667 4.73438
                    8.80729L0.9375 5.00521C-0.213542 3.85417 -0.213542 2.02083
                    0.9375 0.937499C2.08854 -0.21875 3.92187 -0.21875 5.00521
                    0.937499L8.79688 4.73437C9.95313 5.89062 9.95313 7.71875 8.79688
                    8.80729C8.25521 9.41667 7.51042 9.6875 6.76562 9.6875Z" fill="#000F8E" />
                <path d="M16.0521 9.6875C15.3073 9.6875 14.5625 9.41667 14.0156
                        8.80729C12.8646 7.65104 12.8646 5.82292 14.0156
                        4.73437L17.8125 0.937499C18.9635 -0.21875 20.7969 -0.21875
                        21.8802 0.937499C23.0313 2.08854 23.0313 3.92187 21.8802
                        5.00521L18.0833 8.80729C17.5417 9.41667 16.7969 9.6875
                        16.0521 9.6875Z" fill="#000F8E" />
                <path d="M2.96875 22.8542C2.22396 22.8542 1.47917 22.5834
                            0.9375 21.9688C-0.213542 20.8177 -0.213542 18.9844
                            0.9375 17.9011L4.73438 14.099C5.88542 12.948 7.71354
                            12.948 8.79688 14.099C9.95313 15.2552 9.95313 17.0834
                            8.79688 18.1719L5.00521 21.9688C4.39583 22.5834 3.71354
                            22.8542 2.96875 22.8542Z" fill="#000F8E" />
                <path d="M19.8438 22.8542C19.099 22.8542 18.3542 22.5834
                                17.8125 21.9688L14.0156 18.1719C12.8646 17.0157
                                12.8646 15.1875 14.0156 14.099C15.1719 12.948 17
                                12.948 18.0833 14.099L21.8802 17.9011C23.0313
                                19.0521 23.0313 20.8855 21.8802 21.9688C21.3385
                                22.5834 20.5938 22.8542 19.8438 22.8542Z" fill="#000F8E" />
            </svg>
        </Box>
            <div className="content-container">
                <Card className="box">
                    <form className="example-form" >
                        <div className="submit-buttons-div">
                            <Button variant="contained" className="submit-button"  style={{ backgroundColor: '#0000a0', color: '#fff' }} onClick={keycloak.login}>
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                                Login with Keycloak
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );







}

export default Login;
