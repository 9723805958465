import {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Typography} from "@mui/material";
import { useLocation } from 'react-router-dom';

function Seevc() {
    const location = useLocation();
    const [initialLoad, setInitialLoad] = useState(true);
    const [listVC,setlistVC]=useState(location.state.vcListe);
    const [code,setCode]=useState(location.state.code);

  useEffect(()=>{

      if (initialLoad) {
          setInitialLoad(false);
          return;
      }
    document.title="SeeVC";

  },[initialLoad]);

  const ShowVC = () => {

      if(code==0){

          return (<div>
                  { listVC.map((item,position) => (
                      <Accordion  sx={{
                          backgroundColor: 'white',
                          wordBreak: 'break-word',
                          marginTop: '10px',
                          marginBottom: '20px'
                          , fontFamily: "Titillium Web"
                      }}>
                          <AccordionSummary
                              style={{fontSize:'12px' , backgroundColor:'#000F8E', color:'white', fontFamily: "Titillium Web" }}
                              aria-controls="panel1a-content"
                              id="panel1a-header">
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {position}
                              </Typography>
                              <Typography >{item.credential.issuer}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography style={{ border: '2px solid #000F8E' , backgroundColor:'#000F8E'}}>
                                  {JSON.stringify(item.credential, null, 2)}
                              </Typography>
                          </AccordionDetails>
                      </Accordion>
                  )) }
              </div>
          );

      }else {

          if (Object.keys(listVC).length!=0) {
              return (<div>
                      {Object.keys(listVC).map((item, position) => (
                          <Accordion sx={{
                              backgroundColor: 'white',
                              wordBreak: 'break-word',
                              marginTop: '10px',
                              marginBottom: '20px', fontFamily: "Titillium Web"
                          }}>
                              <AccordionSummary
                                  style={{
                                      fontSize: '12px'
                                      , backgroundColor:'#000F8E', color:'white',
                                      justifyContent: 'center',
                                      alignItems: 'center', fontFamily: "Titillium Web"
                                  }}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                              >
                                  <Typography sx={{width: '33%', flexShrink: 0}}>
                                      {position}
                                  </Typography>
                                  <Typography>{item}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                  <Typography>
                                      {JSON.stringify(listVC[item], null, 2)}
                                  </Typography>
                              </AccordionDetails>
                          </Accordion>
                      ))}
                  </div>
              );
          }
      }

  }

  return (
      <Container sx={{ backgroundColor: 'dark', fontFamily: "Titillium Web" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: "Titillium Web" }}>
              <h1>
                  <u>ALL VC</u>
              </h1>
          </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', fontFamily: "Titillium Web" }}>
              <ShowVC/>
          </Box>
      </Container>
  );
}
export default Seevc;
