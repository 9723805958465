import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Seevc from './Seevc';
import PrivateRoute from './helpers/PrivateRoute';

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/login" element={<Login /> }/>
          <Route path="/portail/:index?" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/seevc" element={<PrivateRoute><Seevc /></PrivateRoute>} />
        </Routes>
      </Router>
  );
};

export default App;
